<template>
  <b-col cols="12">
    <!-- {{$i18n.locale}} -->
    <b-card class="media-list">
      <b-row class="d-flex align-items-center">
        <b-col class="profile-side">
          <b-row class="my-0 css-reset">
            <b-media class="mb-2 css-reset">
              <b-img ref="previewEl" :src="userImg" class="profile-img my-2" center />
            </b-media>
          </b-row>
          <b-row class="center profile-edit-buttons">
            <div class="d-flex flex-wrap">
           <b-button
                  variant="primary upload-button"
                  @click="toggleShow">
                <span class="d-none d-sm-inline">
                    <b-icon-capslock-fill class="up-icon" />
                    <span class="up-text">{{ $t("Upload") }}</span>
                </span>
                <b-icon-capslock-fill class="up-icon d-inline d-sm-none" />
                  </b-button>
                  <my-upload field="img"
                    @crop-success="cropSuccess"
                    v-model="show"
                    :width="150"
                    :height="150"
                    langType="tr"
                    :params="params"
                    :headers="headers"
                    img-format="png">
                  </my-upload>
              <b-button variant="danger remove-button"
                class="ml-1" @click="removePhoto">
                <span class="d-none d-sm-inline">
                  <b-icon-trash class="remove-icon" />
                  <span class="remove-text">{{ $t("Remove") }}</span>
                </span>
                <b-icon-trash class="remove-icon d-inline d-sm-none" />
              </b-button>
            </div>
          </b-row>
        </b-col>
        <b-col>
          <b-form ref="form">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :label="$t('Name')" label-for="first-name">
                <b-input-group>
                  <b-form-input
                    @blur="$v.personData.name.$touch()"
                    :class="{ 'is-invalid': $v.personData.name.$error }"
                    id="first-name"
                    type="text"
                    :placeholder="$t('Name')"
                    v-model="personData.name"/>
                    <small v-if="isFinal && !$v.personData.name.required" class="form-text text-danger">{{ $t("The name field is required") }}</small>
                </b-input-group>
              </b-form-group>

              <b-form-group :label="$t('Surname')" label-for="last-name">
                <b-input-group>
                  <b-form-input
                    @blur="$v.personData.last_name.$touch()"
                    :class="{'is-invalid': $v.personData.last_name.$error,}"
                    id="last-name"
                    type="text"
                    :placeholder="$t('Surname')"
                    v-model="personData.last_name"/>
                  <small v-if="isFinal && !$v.personData.last_name.required" class="form-text text-danger">{{ $t("The surname field is required") }}</small>
                </b-input-group>
              </b-form-group> 

              <b-form-group :label="$t('Identity')" label-for="Identity">
                <b-input-group>
                  <b-form-input
                    @blur="$v.personData.identification_number.$touch()"
                    :class="{'is-invalid': $v.personData.identification_number.$error }"
                    id="identity"
                    type="number"                    
                    :placeholder="$t('Identity')"
                    v-model="personData.identification_number"/>                    
                </b-input-group>
                      <small v-if="isFinal && !$v.personData.identification_number.required" class="form-text text-danger" > {{$t("ID number required")}}</small>
                      <small v-if="isFinal && !$v.personData.identification_number.numeric" class="form-text text-danger" > {{$t("ID number must consist of numbers")}}</small>
                      <small v-if="isFinal && !$v.personData.identification_number.maxLength" class="form-text text-danger" >{{ $t("ID number length must be 11 digits")}}</small>
                      <small v-if="isFinal && !$v.personData.identification_number.minLength" class="form-text text-danger" >{{ $t("ID number length must be 11 digits")}}</small>
              </b-form-group>

              <b-form-group :label="$t('Birthday')" label-for="birthday" class="mb-1">
             
                <b-input-group class="mb-0">
                  <flat-pickr
                     @blur="$v.personData.birthday.$touch()"
                    :placeholder="$t('Birthday')"
                    v-model="personData.birthday"
                    class="form-control"
                    :config="config"/>
                  </b-input-group>
               <small v-if="isFinal && !$v.personData.birthday.required" class="form-text text-danger">{{ $t("The birthday field is required") }}</small>
            </b-form-group>
            <b-col class="m-0 p-0">
              <div>
                <label for="example-input">{{ $t("Gender") }}</label>
              </div>
              <div>
                <b-button-group>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="md"
                    :variant="maleColor"
                    @click="getGender('male')">
                    {{ $t("Male") }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="femaleColor"
                    size="md"
                    @click="getGender('female')">
                    {{ $t("Female") }}
                  </b-button>
                </b-button-group>
              </div>
               <small v-if="isFinal && !$v.personData.gender.numeric" class="form-text text-danger" > {{$t("Gender required")}}</small>
            </b-col>
          </b-col>

          <b-col cols="12" md="6">
          <b-form-group :label="$t('E-Mail')" label-for="email">
                <b-form-input
                  @blur="$v.personData.email.$touch()"
                  id="email"
                  v-model="personData.email"
                  type="email"
                  disabled
                />
                <small
                  v-if="isFinal && !$v.personData.email.required"
                  class="form-text text-danger"
                  >{{ $t("This field is required") }}</small>
                <small v-if="isFinal && !$v.personData.email.email" class="form-text text-danger">{{$t("The email field must be a valid email")}}</small>
              </b-form-group>

              
          <b-form-group :label="$t('Phone')" label-for="phone">
                <b-input-group>
                  <b-form-input
                    @blur="$v.personData.phone_number.$touch()"
                    :class="{ 'is-invalid': $v.personData.phone_number.$error }"
                    id="phone"
                    type="number"
                    v-model="personData.phone_number"
                    :placeholder="$t('Phone')"/>                
                </b-input-group>      
                  <small v-if="isFinal && !$v.personData.phone_number.required" class="form-text text-danger" > {{$t("The phone number field format is invalid")}}</small>
                    <small v-if="isFinal && !$v.personData.phone_number.numeric" class="form-text text-danger" > {{$t("The phone number field format is invalid")}}</small>
                    <small v-if="isFinal && !$v.personData.phone_number.maxLength" class="form-text text-danger" >{{ $t("The phone number field format is invalid")}}</small>       
            </b-form-group>




               <b-form-group :label="$t('City')" label-for="city">
                      <v-select
                        style="width: 100%; z-index: 450; display: inline-block"
                        :reduce="x => x.city_id"  
                        v-model="selectedCity.city_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="cities"
                        value="city_id"
                        label="city_name"
                        name="city"
                        id="city"                    
                        @blur="$v.personData.city_id.$touch()"
                        ></v-select>
                        <small v-if="isFinal&& !$v.personData.city_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                     
                    </b-form-group>

                    <b-form-group :label="$t('Town')" label-for="town">
                      <v-select
                        style="width: 100%; z-index: 400; display: inline-block"
                        :reduce="x => x.town_id"
                        v-model="selectedTown.town_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="towns"
                        value="town_id"
                        label="town_name"
                        name="town"
                        @blur="$v.personData.town_id.$touch()"
                      >
                      </v-select>
                        <small v-if="isFinal && !$v.personData.town_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                    </b-form-group>

          <b-form-group
                :label="$t('Address')"
                label-for="address">
                <b-input-group>
                  <b-form-textarea 
                    @blur="$v.personData.address.$touch()"                        
                    id="address"
                    rows="7"
                    v-model="personData.address"/>
                </b-input-group>
             
                        <small v-if="isFinal && !$v.personData.address.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
              </b-form-group>
            </b-col>
          </b-row>  
        </b-form>
        </b-col>
      </b-row>
      <!-- <b-row class="d-flex justify-content-center mt-1" v-if="$v.$invalid">
        <span class="text-danger">{{
          $t("Please make sure to verify all fields")
        }}
        </span>
      </b-row> -->
      <b-row class="float-right mt-1">
        <b-col>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="mr-1 shadow cancel-button" size="md" variant="outline-danger"
            :to="{ name: 'customerList' }">
            <b-icon-dash-circle></b-icon-dash-circle>
            {{$t("Cancel")}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="md"
            variant="outline-success"
            class="shadow update-button"
            @click="updatePersonData()"><b-icon-check></b-icon-check>{{$t("Update")}}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAvatar,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardCode,
  BCol,
  BForm,
  BFormTextarea,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BMedia,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import 'cleave.js/dist/addons/cleave-phone.us'
import { required, numeric, maxLength,minLength, email } from 'vuelidate/lib/validators'
import myUpload from 'vue-image-crop-upload/upload-2.vue'

import flatPickr from 'vue-flatpickr-component'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  setup() {
    const userImg = ref('')
    const previewEl = ref(null)
    let refInputEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, function (
        base64
    ) {
      userImg.value = base64
    });
    return {
      refInputEl,
      inputImageRenderer,
      userImg,
      previewEl,
    };
  },
  components: {
    // TabContent,
    BForm,
    BButton,
    BInputGroupPrepend,
    BButtonGroup,
    BAvatar,
    BFormDatepicker,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BFormTextarea,
    BMedia,
    BCard,
    BCardCode,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BBreadcrumb,
    BBreadcrumbItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    'my-upload': myUpload
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedCity:{
        city_id:null,//"00000000-0000-0000-0000-000000000000",
        city_name: this.$t('Please choose')
      },
      selectedTown:
      {
        town_id:null,//"00000000-0000-0000-0000-000000000000",
        town_name: this.$t('Please choose')
      },
      dataloaded:false,
      towns: [],
      cities: [],

      image: "",
      formatted: "",
      selected: "",
      personData: {
        phone_number: "",
        gender:"-",
        avatar: "",
        name:null,
        last_name:null,
        identification_number:"",
        city_id:null,
        town_id:null,
        address:"",
        is_addresses_equal:true 
      },
      date: {
        date: true,
        delimiter: "-",
        datePattern: ["Y", "m", "d"],
      },
      block: {
        blocks: [25],
        uppercase: false,
      },
      //   codeIcon,
      maleColor: "outline-primary",
      femaleColor: "outline-primary",
      person_id: "",
      config: {
        dateFormat: "d.m.Y",
        locale: this.$i18n.locale === 'en' ? '' : Turkish,
        maxDate: "12.12.2009",
        minDate: "01.01.1940",
      },
      // inputImageRenderer:{},
      CompanyBranchId: '',

      show: false,
      isFinal: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      userImg: ''
    };
  },
    validations: {
    personData: {
      name: {
        required:required
      },
      email: {
        email,
        required:required
      },
      last_name: {
        required:required
      },
      phone_number: {
         required:required,
         numeric:numeric,
         maxLength: maxLength(20)
      },
       identification_number:{
           required:required,
          numeric:numeric,
          maxLength: maxLength(11),
          minLength: minLength(11)
      },
      birthday:{
           required:required
      },
      gender:{
           numeric:numeric
      },
      town_id:{
        required:required
      },
      city_id:{
        required:required
      },
       address:{
         required:required
      }
    }
  },
  methods: {
    getPersonData() {
      this.$database.CustomerService.get_customer_person_by_person_id(this.person_id).then(
        (res) => {
          // if (Object.keys(res.result).length === 0) {
          //   return
          // }
           this.getCities();


        //  const full_name = res.Result.FirstName + " " + res.Result.LastName;
          
         // this.personData.full_name = full_name;
          this.personData.name= res.Result.FirstName;
          this.personData.last_name= res.Result.LastName;
          this.personData.identification_number= res.Result.IdentificationNumber
          this.personData.birthday=this.$functions.ConvertToTurkishDateFormat(res.Result.Birthday)
          this.personData.gender= res.Result.Gender;
          this.personData.email=res.Result.Email;
          this.personData.phone_number=res.Result.PhoneNumber;
          this.personData.user_img=res.Result.UserImg;

          this.personData.address=res.Result.Address;
          this.getGender(this.personData.gender);


          // this.personData = res.result; 
          // this.personData.birthday = this.$functions.ConvertToTurkishDateFormat(res.result.birthday)
          // this.getGender(this.personData.gender);

           this.userImg = this.personData.user_img; 
           
          
          if(res.Result.CityId !="00000000-0000-0000-0000-000000000000")
          {
             this.personData.city_id=res.Result.CityId
            this.selectedCity.city_id=this.personData.city_id
          }
         
          if(res.Result.TownId !="00000000-0000-0000-0000-000000000000")
          {
               this.personData.town_id=res.Result.TownId
               this.selectedTown.town_id= this.personData.town_id
          }
        }
      );
    },
    updatePersonData() 
    {
        this.isFinal = true
        if (this.$v.$invalid)
        {
            return;
        }


            
      this.$database.CustomerService.CustomerCreate(
          this.$models.facilityPersonEditModel(this.CompanyBranchId,this.personData.name,this.personData.last_name, this.personData.email.toLowerCase(), this.personData.phone_number, this.$functions.ConvertToEnglishDateFormat(this.personData.birthday),this.personData.identification_number,this.personData.gender,this.personData.city_id,this.personData.town_id,this.personData.is_addresses_equal, this.personData.address,this.userImg)
      ).then((res) => 
      {
        if(res.IsSuccess == true || res.IsSuccess == 1){
          if(res.Result == 'Customer Tanımlandı'){
            this.$functions.Messages.success('Customer Successfully Identified')
            this.$router.push({name: 'customerList'})
          }
          else {
            this.$functions.Messages.success('Customer Successfully Edited')
            this.$router.push({name: 'customerList'})
          }
        }else {
            this.$functions.Messages.error('Action failed!')
        }
      });
    },
    getGender(val) {
      // this.gender = val;
      if (val === "female" || val === 0) {
        this.femaleColor = "primary";
        this.maleColor = "outline-secondary";
        this.personData.gender = 0;
      } else if (val === "male" || val === 1) {
        this.personData.gender = 1;
        this.maleColor = "primary";
        this.femaleColor = "outline-secondary";
      } 
      // else {
      //   this.gender = 3;
      // }
    },

     async getCities()
    {
        await   this.$database.DistributorService.studio_get_cities().then((res) => {
        if (!res.is_success) return;
        this.cities = res.result;
          this.dataloaded=true,


        this.getTowns();
      });
    },

    getTowns() {

      this.$database.DistributorService.studio_get_towns_by_city_id(
        this.personData.city_id
      ).then((res) => {
        if (!res.is_success) return;
        this.towns = res.result;
      });
    },

    removePhoto() {
      //temp default user img
      this.userImg = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png'
    },
    toggleShow() {
				this.show = !this.show;
			},
            /**s
			 * crop success
			 *
			 * [param] userImg
			 * [param] field
			 */
			cropSuccess(userImg, field){
				this.userImg = userImg;
        console.log(this.userImg);
      },
			/**
			 * upload success
			 *
			 * [param] jsonData  server api return data, already json encode
			 * [param] field
			 */
			cropUploadSuccess(jsonData, field){
				console.log('-------- upload success --------');
				console.log(jsonData);
				console.log('field: ' + field);
			},
			/**
			 * upload fail
			 *
			 * [param] status    server api return error status, like 500
			 * [param] field
			 */
			cropUploadFail(status, field){
				console.log('-------- upload fail --------');
				console.log(status);
				console.log('field: ' + field);
			},
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedDMY;
    },
  },
   watch: {
    'selectedCity.city_id'(val)
     {
      if(this.dataloaded===true)
      {
        this.selectedTown = {};
        this.personData.city_id = val;
        this.towns = [];
        this.$database.DistributorService.studio_get_towns_by_city_id(
          val

        ).then((res) => {
          if (!res.is_success)
              return;
              this.towns = res.result;
             // this.selectedTown.town_id="00000000-0000-0000-0000-000000000000";
              this.selectedTown.town_name="Lütfen Seçim Yapınız";
        });
      }
    },
    'selectedTown.town_id'(val) {
      this.personData.town_id = val;
    },
   },
  mounted()
  {

  },
  created() {
    this.userImg = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png'
    this.person_id = this.$route.params.personID;

    this.CompanyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
    this.getPersonData();
  },

};
</script>

<!-- stillendirme -->
<style lang="css" src="@/styles/css/personEdit.css" scoped></style>
<style lang="scss" src="@/styles/scss/personEdit.scss" scoped></style>
