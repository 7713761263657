<template>
  <component :is="'b-card'">

    <!-- Alert: No item found -->
    <b-tabs
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{$t("Account")}}</span>
        </template>
        <customer-edit-tab
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{$t("Information")}}</span>
        </template>
        <customer-information-tab class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import customerEditTab from './customerEditTab.vue'
import customerInformationTab from './customerInformationTab.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    customerEditTab,
    customerInformationTab,
  },
  
}
</script>

<style scoped>

</style>
