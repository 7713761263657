<template>
  <div>
    <div
      class="media mb-2 d-flex justify-content-center align-items-center card bg-gradient-primary p-3"
    >
      <div>
        <b-media class="mb-2 css-reset">
          <b-img ref="previewEl" :src="userImg" class="profile-img" center />
          <h3 class="text-center mt-1 text-white">{{ fullName }}</h3>
        </b-media>
      </div>
    </div>
    <div class="card bg-primary bg-lighten-2 p-1 user-info">
      <div class="row">
          <div class="col-md-12 col-12" v-if="typeof is_confirm != 'undefined'">
          <div class="card p-2">
            <div class="row bg-dark rounded">
                <div class="col">
                       <div class="media p-1">
              <div class="media-aside mr-1 align-self-start">
                         <span
                  class="b-avatar badge-light-white rounded-circle"
                  style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"
                    >

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg>
                    </span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0 text-white invite-status-title">
                  {{ $t("Invite Status") }}:
                </h4>

              </div>
            </div>
                </div>
                <div class="col">
                           <div class="media p-1 rounded" v-if="is_confirm === false">
                      <div class="media-aside mr-1 align-items-end">
                                <span
                          class="b-avatar badge-light-danger rounded-circle"
                          style="width: 48px; height: 48px;"
                          ><span class="b-avatar-custom"
                            >

                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg></span><!----></span>
                      </div>
                      <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0 text-white approved-title">
                          {{$t('Not Approved')}}
                        </h4>
                      </div>
                </div>
                <div class="media bg-dark p-1 rounded" v-if="is_confirm === true">
                      <div class="media-aside mr-1 align-self-start">
                                <span
                          class="b-avatar badge-light-success rounded-circle"
                          style="width: 48px; height: 48px;"
                          ><span class="b-avatar-custom"
                            >

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><path d="M22 11.07V12a10 10 0 1 1-5.93-9.14"></path><polyline points="23 3 12 14 9 11"></polyline></svg>                            </span
                          ><!----></span
                        >
                      </div>
                      <div class="media-body my-auto">
                        <h4 class="font-weight-bolder mb-0 text-white approved-title">
                          {{$t('Approved')}}
                        </h4>

                      </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="card p-2">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-dark badge-light-dark rounded-circle"
                  style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-activity"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-mail"
                      >
                        <path
                          d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                        ></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0 text-dark">
                  {{ $t("E-Mail") }}
                </h4>
                <p class="card-text font-small-3 mb-0">
                  {{ personData.email }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="card p-2">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-dark badge-light-dark rounded-circle"
                  style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-activity"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-phone"
                      >
                        <path
                          d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                        ></path>
                      </svg></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0 text-dark">
                  {{ $t("Phone") }}
                </h4>
                <p class="card-text font-small-3 mb-0" v-if="personData.phone_number.length === 0">
                  {{
                      $t("No information provided") + "!"
                  }}
                </p>
                 <p class="card-text font-small-3 mb-0" v-else>
                  {{
                      personData.phone_number
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="card p-2">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-dark badge-light-dark rounded-circle"
                  style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-activity"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-pie-chart"
                      >
                        <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                        <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                      </svg></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0 text-dark">
                  {{ $t("Birthday") }}
                </h4>
                <p class="card-text font-small-3 mb-0" v-if="personData.birthday.length === 0">
                  {{

                    $t("No information provided") + "!"

                  }}
                </p>
                <p class="card-text font-small-3 mb-0" v-else>
                    {{personData.birthday}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="card p-2">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-dark badge-light-dark rounded-circle"
                  style="width: 48px; height: 48px;"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-activity"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-user"
                      >
                        <path
                          d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                        ></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0 text-dark">
                  {{ $t("Gender") }}
                </h4>
                <p class="card-text font-small-3 mb-0">
                  {{
                    personData.gender === 1
                      ? $t("Male")
                      : personData.gender === 2
                      ? $t("Female")
                      : $t("No information provided") + "!"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BForm,
  BRow,
  BImg,
  BAvatar,
  BCol,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BMedia,
  BInputGroupPrepend,
  BCard,
  BCardCode,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { ref } from "@vue/composition-api";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import "cleave.js/dist/addons/cleave-phone.us";
import flatPickr from "vue-flatpickr-component";
import { turkey } from "flatpickr/dist/l10n/tr.js";
export default {
  setup() {
    const userImg = ref("");
    const previewEl = ref(null);
    let refInputEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, function(
      base64
    ) {
      userImg.value = base64;
    });
    return {
      refInputEl,
      inputImageRenderer,
      userImg,
      previewEl,
    };
  },
  components: {
    // TabContent,
    BButton,
    BForm,
    BInputGroupPrepend,
    BButtonGroup,
    BAvatar,
    BFormDatepicker,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardCode,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BBreadcrumb,
    BBreadcrumbItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      image: "",
      formatted: "",
      selected: "",
      gender: 3,
      personData: {
        birthday: "",
        phone_number: "",
        gender: 3,
        avatar: "",
        name: "",
        full_name: "",
      },
      fullName: "",
      block: {
        blocks: [25],
        uppercase: false,
      },
      //   codeIcon,
      maleColor: "outline-primary",
      femaleColor: "outline-primary",
      person_id: "",
      config: {
        dateFormat: "Y.m.d",
        locale: turkey,
        maxDate: "2009.12.31",
        minDate: "1940.01.01",
      },
      activityStatus: null,
      // inputImageRenderer:{},
      is_confirm: null,
      companyBranchId: ''
    };
  },
  methods: {
    getPersonData() {
      this.$database.StudioService.get_person_by_person_id(this.person_id).then(
        (res) => {
          if (Object.keys(res.result).length === 0) return;
          const full_name = res.result.name + " " + res.result.last_name;
          this.fullName = full_name;
          this.personData = res.result;
          this.personData.birthday =this.$functions.ConvertToTurkishDateFormat(this.personData.birthday);
          // this.personData.birthday ===   "01.01.1899"
          //   ? ""
          //   : this.personData.birthday
          //       .split("-")
          //       .reverse()
          //       .join(".");
          this.gender = this.personData.gender;
          this.getGender(this.personData.gender);
          this.userImg = this.personData.image; //- aktif edilecek
        }
      );
    },
    getCustomerIsConfirm(){
       this.$database.StudioService.customer_is_confirm_check(
        this.$route.params.personID,
        this.companyBranchId,
      ).then(res => {
        this.is_confirm = res.result.is_confirm
      })
    },
    getGender(val) {
      // this.gender = val;
      if (val === "female" || val === 0) {
      this.femaleColor = "primary";
        this.maleColor = "outline-secondary";
        this.gender = 0;
      } else if (val === "male" || val === 1) {
        this.gender = 1;
        this.maleColor = "primary";
        this.femaleColor = "outline-secondary";
      } else {
        this.gender = 3;
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedDMY;
    },
  },
  created(){
    this.userImg = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png';
    this.companyBranchId = this.$store.getters["auth/userInfo"].company_branch_id
    this.person_id = this.$route.params.personID;
    this.getPersonData();
  },
  mounted() {
    this.getCustomerIsConfirm();
  },
};
</script>

<style scoped src="@/styles/css/customerInformationTab.css"></style>
<style lang="scss" src="@/styles/scss/customerInformationTab.scss"></style>
